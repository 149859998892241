import {
  Country, isNotNullOrUndefined, isNullOrUndefined, isNullOrUndefinedOrEmpty, OperationResponse,
  TimeZoneRegion, toEnumFromStringOrNull, TrueTime
} from 'in-time-core';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { logWarning, logInfo } from 'in-time-logger';
import { CookieService } from './cookie.service';
import dayjs from 'dayjs';
import { environment } from '../app.environment';
import { STORAGE_LOCATION } from '../core/utils/storage-keys';
import { ErrorType } from '../core/models/error-type';

export type IpCheckResponse = {
  country_code?: string;
  region_name?: string;
  city?: string;
  latitude?: number;
  longitude?: number;
  time_zone?: {
      id?: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  private static readonly LOCATION_STORAGE_EXPIRATION_DURATION = dayjs.duration(1, 'month');

  private readonly cookieService = inject(CookieService);
  private readonly http = inject(HttpClient);

  private readonly _geoLocationServiceUrl: string | null;
  private _country: Country | null = null;
  private _countryCode: string | null = null;
  private _region: string | null = null;
  private _city: string | null = null;
  private _latitude: number | null = null;
  private _longitude: number | null = null;
  private _timezone: TimeZoneRegion | null = null;

  get country(): Country | null {
    return this._country;
  }

  get countryCode(): string | null {
    return this._countryCode;
  }

  get region(): string | null {
    return this._region;
  }

  get city(): string | null {
    return this._city;
  }

  get latitude(): number | null {
    return this._latitude;
  }

  get longitude(): number | null {
    return this._longitude;
  }

  get timezone(): TimeZoneRegion | null {
    return this._timezone;
  }

  constructor() {
    let geoLocationServiceUrl = isNotNullOrUndefined(environment.apiGatewayUrl) ? `${environment.apiGatewayUrl}/geo/checkIp` : null;
    if(environment.useGeoEmulator) {
      geoLocationServiceUrl = 'http://localhost:8083/whatIsMyLocation';
    }

    this._geoLocationServiceUrl = geoLocationServiceUrl;
  }

  async load(options: { canDoIpCheck: boolean }): Promise<void> {
    const cookiesResponse = this.fetchLocationFromCookies();
    if(cookiesResponse.success) {
      const location = cookiesResponse.data;

      if(isNotNullOrUndefined(location.country_code)) {
        this.loadFromIpCheckResponse(location);
        return;
      }
    }

    if(options.canDoIpCheck) {
      logInfo('Trying to load location by IP...');
      const ipResponse = await this.fetchLocationByIp();
      if(!ipResponse.success) {
        return;
      }

      const location = ipResponse.data;
      this.loadFromIpCheckResponse(location);

      this.cookieService.set(
        STORAGE_LOCATION,
        JSON.stringify(location), {
          expires: GeoLocationService.getLocationStorageExpirationDate(),
        }
      );
    }
  }

  private fetchLocationFromCookies(): OperationResponse<IpCheckResponse> {
    try {
      const json = this.cookieService.get(STORAGE_LOCATION);
      if(isNullOrUndefinedOrEmpty(json)) {
        logInfo('Could not fetch location from cookies. No data found.');
        return OperationResponse.error(ErrorType.InvalidLocation);
      }

      const result = JSON.parse(json) as unknown;
      if(isNullOrUndefined(result)) {
        logWarning(`Failed to fetch location from cookies. Response is null or undefined: ${JSON.stringify(result, null, 2)}`);
        return OperationResponse.error(ErrorType.InvalidLocation);
      }

      if(!(result instanceof Object)) {
        logWarning(`Failed to fetch location from cookies. Response is not an object: ${JSON.stringify(result, null, 2)}`);
        return OperationResponse.error(ErrorType.InvalidLocation);
      }

      const values = Object.values(result);
      if(values.every(value => isNullOrUndefined(value))) {
        logWarning(`Failed to fetch location from cookies. Response does not contain any valid values: ${JSON.stringify(result, null, 2)}`);
        return OperationResponse.error(ErrorType.InvalidLocation);
      }

      return OperationResponse.success(result);
    }
    catch(error) {
      logWarning(`Failed to fetch location from cookies: ${error}`);
      return OperationResponse.error(ErrorType.InvalidJSON);
    }
  }

  private async fetchLocationByIp(): Promise<OperationResponse<IpCheckResponse>> {
    if(isNullOrUndefined(this._geoLocationServiceUrl)) {
      logWarning('Geo location by IP is not available in this environment.');
      return OperationResponse.error(ErrorType.GeoLocationNotAvailable);
    }

    try {
      const response = await this.http.get(this._geoLocationServiceUrl).toPromise();

      if(isNullOrUndefined(response)) {
        logWarning(`Failed to fetch location by IP. Response is null or undefined: ${JSON.stringify(response, null, 2)}`);
        return OperationResponse.error(ErrorType.InvalidLocation);
      }

      if(!(response instanceof Object)) {
        logWarning(`Failed to fetch location by IP. Response is not an object: ${JSON.stringify(response, null, 2)}`);
        return OperationResponse.error(ErrorType.InvalidLocation);
      }

      const values = Object.values(response);
      if(values.every(value => isNullOrUndefined(value))) {
        logWarning(`Failed to fetch location by IP. Response does not contain any valid values: ${JSON.stringify(response, null, 2)}`);
        return OperationResponse.error(ErrorType.InvalidLocation);
      }

      logInfo(`Location has been fetched by IP successfully: ${JSON.stringify(response, null, 2)}`);
      return OperationResponse.success(response);
    }
    catch(error) {
      logWarning(`Failed to fetch location by IP: ${JSON.stringify(error, null, 2)}`);
      return OperationResponse.error(ErrorType.GeoLocationNotAvailable);
    }
  }

  private loadFromIpCheckResponse(json: IpCheckResponse): void {
    this._country = toEnumFromStringOrNull(json.country_code, Country);
    this._countryCode = json.country_code ?? null;
    this._region = json.region_name ?? null;
    this._city = json.city ?? null;
    this._latitude = json.latitude ?? null;
    this._longitude = json.longitude ?? null;
    this._timezone = toEnumFromStringOrNull(json.time_zone?.id, TimeZoneRegion) ?? null;
  }

  private static getLocationStorageExpirationDate(): Date {
    return TrueTime.now().add(GeoLocationService.LOCATION_STORAGE_EXPIRATION_DURATION).toDate();
  }
}
